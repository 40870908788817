<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Email from "../../components/email";
import Multiselect from "vue-multiselect";
import orderstatus from "../../components/orderstatus";
import relateclient from "../../components/relateclient";
import {lookup2,disabledBeforeTodayDates,downFile,checkRole} from '@/apis/common'
import {saveOrder,orderDetail,queryQuotation,saveQuotation,createQuotationFile,createApplicationForm,setOrderSales} from '@/apis/order'
import {getNetDiskFile} from '@/apis/disk'
import {queryStaffList} from '@/apis/user'
import {
  required
} from "vuelidate/lib/validators";
/**
 * Form Repeater component
 */
export default {
  page: {
    title: "ZDHC VERIFIED INCHECK LEVEL 1 APPLICATION FORM",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout,DatePicker,Multiselect,Email,orderstatus,relateclient},
  validations: {
    form: {
      clientName: { 
            required,
      },
      taxCode: { 
        check(value){
            if (localStorage.getItem('language')!="zh-CN"&&value!=null && value.trim().length === 0) {
              return false;
            }else{
              return true;
            }                
        },
      },
      country: { 
        required,
      },
      industry: { 
        required,
      },      
      address: { 
            required,
      },
      contact: { 
            required,
      },
      title: { 
            required,
      },
      mobile: { 
            required,
      },
      email: { 
            required,
      },
      promoteBrand: { 
            required,
      },
      companyInfo:{
          wwt:{
            required
          },
          productionArea:{
            required
          },
          administration:{
            required
          },	
          chemicalStorageArea:{
            required
          },
          other:{
            required
          },
          employee:{
            required
          },
          openingHour:{
            required
          },
          productType:{
            required
          },
          technology:{
            required
          },
          materials:{
            required
          },
          cmc:{
            required
          },
          numberofChemical:{
            required
          },
          mrsl:{
            required
          },
          chemicalUsed:{
            required
          },
      },
      orderDetail:{
        auditDateFrom:{
          required
        },
        auditDateTo:{
          required
        },
        model:{
          required
        },
        experience:{
          required
        },
        performance:{
          required
        },
        zdhcID:{
          required
        },       
      }
    },
  },
  data() {
    return {
      title: "ZDHC VERIFIED INCHECK LEVEL 1 APPLICATION FORM",
      action:"new",
      orderNo:null,
      clientNo:null,
      disabledDates: disabledBeforeTodayDates,
      quotationData:[],
      orderStatusShow:false,
      relateClientShow:false,
      curSales:[],
      salesList:[],
      attachmentFiles:[],
      form:{
        orderId:null,
        orderType:"ZDHC-IL1",
        orderSource:"WP",
        orderStatus:'N',
        paymentStatus:'Y',
        sales:"",
        handleSales:"",
        orderDetail:{
          model:"T1",
          experience:"F",
          performance:"Y",
          auditDateFrom:"",
          auditDateTo:"",
          zdhcID:""
        },
        clientId:null,
        clientName:"",
        clientNameEn:"",
        taxCode:"",
        country:"",
        province:"",
        city:"",
        station:"",
        salesTag:"",        
        address:"",
        addressEn:"",
        promoteBrand:"",
        companyInfo:{
          productType:"",
          technology:"",
          materials:"",
          wwt:"",
          productionArea:"",
          administration:"",	
          chemicalStorageArea:"",
          other:"",
          employee:"",
          openingHour:"",
          fromFactoryAirport:"",
          cmc:"",
          numberofChemical:"",
          mrsl:"Y",
          chemicalUsed:"",
        },
        industry:"",
        contact:"",
        email:"",
        mobile:"",
        title:"",
        department:"",
        formFile:""
      },
      submitted:false,
      lookupRawData:{},
      lookupData:{
        industry:[],
        travel:[],
        cnty:[]
      },
      lookupDataSelected:{
        industry:[],
        cnty:[]
      },
      workDay:0,
      templateId:"QUOTATION-HIGG",
      quotationEmail:{},
      quotation:{
        orderId:null,
        currencyType:"USD",
        language:"CN",
        workDay:0,
        platformCharge:300,
        vat:1,
        groundTravel:300,
        total:100,
        totalRmb:700,
        serviceItems:[{
          itemType:1,
          itemCode:"verf",
          itemName:"服务费用",
          itemPrice:650
        },{
          itemType:1,
          itemCode:"gt",
          itemName:"OPE",
          itemPrice:300
        }]
      },
      quotationInitData:{},
      quotationAction:'data'
    };
  },
  created(){
    this.quotationInitData = {...this.quotation}
    if(this.$route.query.clientNo!=undefined){
      this.clientNo = this.$route.query.clientNo;
    }
    if(this.$route.query.orderNo!=undefined){
      this.orderNo = this.$route.query.orderNo;
    }
    if(this.$route.query.action!=undefined){
      this.action = this.$route.query.action;
    }
    this.init();
  },
  mounted() {
  },
  computed:{
    showAssignButton(){
      let role = checkRole("SALES_LEADER,SYS_ADMIN")
      if(this.action=="detail" && role){
        return true;
      }else{
        return false;
      }      
    },
    showRelateClientButton(){
      if(this.action=="detail" && this.form.clientId==undefined){
        return true;
      }else{
        return false;
      }
    },
    showFollowupButton(){
      if(this.action!="new"){
        return true;
      }else{
        return false;
      }
    },
    showDownloadFormFile(){
      if(this.form.formFile!=null && this.form.formFile!=""){
        return true;
      }else{
        return false;
      }
    },
    enableNewQuotation(){
      var statusList = ['N','FU','Q']
      if(statusList.includes(this.form.orderStatus)){
        return true
      }
      return false
    },
    totalUsd(){
      return "USD "+this.quotation.total
    },
    totalRmb(){
      return "RMB "+this.quotation.totalRmb
    }
  },
  watch:{
    "lookupDataSelected.cnty"(){
      if(this.lookupDataSelected.cnty.value!=undefined){
        this.form.country = this.lookupDataSelected.cnty.value
      }
    },
    "lookupDataSelected.industry"(){
      if(this.lookupDataSelected.industry.value!=undefined){
        this.form.industry = this.lookupDataSelected.industry.value
      }
    },    
    "form.address"(){
      if(this.form.address.indexOf("省")!=-1||this.form.address.indexOf("市")!=-1){
        let address = this.form.address.replace("，",",").split("市")[0].replace("中国","").trim()
        if(address.indexOf("省")!=-1){
          let province_city = address.split("省")
          this.form.city = ""||province_city[1].trim()
        }else{
          this.form.city = address
        }
      }
    },
    workDay(newValue){
      this.quotation.workDay = parseFloat(newValue)
      this.quotation.serviceItems.forEach(item => {
        if(item.itemCode=="verf"){
          if(this.quotation.currencyType=="USD"){
            item.itemPrice = parseInt(650*newValue)
          }else{
            item.itemPrice = parseInt(7.25*650*newValue)
          }
        }
      });
      if(this.form.city!=""){
        this.globalTravel(this.form.city,newValue)
      }      
    },
    "quotation.currencyType"(newValue,oldValue){
      this.quotation.serviceItems.forEach(item => {
          if(oldValue=="USD" && newValue=="RMB"){
            item.itemPrice = parseInt(item.itemPrice*7.25)
          }else if(newValue=="USD" && oldValue=="RMB"){
            item.itemPrice = parseInt(item.itemPrice/7.25)
          }
      });
    },
    quotation:{
        handler(){
          var total = 0;
          this.quotation.serviceItems.forEach(item => {
            if(item.itemType==1){
              total+=parseFloat(item.itemPrice)
            }else{
              total-=parseFloat(item.itemPrice)
            }
          });
          //税率4舍5入
          total += parseFloat((total*this.quotation.vat/100).toFixed(1))
          if(this.quotation.currencyType=='USD'){
            this.quotation.total = Math.floor(total)
          }else{
            this.quotation.total = Math.floor(total/7.25)
          }
          
          if(this.quotation.currencyType=='USD'){
            this.quotation.totalRmb = Math.floor(total*7.25)
          }else{
            this.quotation.totalRmb = Math.floor(total)
          }
          
        },
        deep:true
    }
  },
  methods: {
    async init(){
      // 初始化数据字典
      this.lookupRawData = await lookup2("INDUSTRY,TRAVEL,CNTY,YES_NO,ZERO_LEVEL2_EXP,TYPE_PROCES");
      this.lookupData.industry = this.lookupRawData["INDUSTRY"];
      this.lookupData.travel = this.lookupRawData["TRAVEL"];
      this.lookupData.cnty = this.lookupRawData["CNTY"];
      this.lookupData.yesno = this.lookupRawData["YES_NO"];
      this.lookupData.experience = this.lookupRawData["ZERO_LEVEL2_EXP"];
      this.lookupData.model = this.lookupRawData["TYPE_PROCES"];
      await queryStaffList({roles:["SALES"]}).then((res)=>{
          this.salesList = res.data;
      })
      // 订单数据载入
      if(this.orderNo!=null){
        orderDetail(this.orderNo).then((res)=>{
          this.form = res.data
          this.form.orderDetail = JSON.parse(this.form.orderDetail)
          this.form.companyInfo = JSON.parse(this.form.companyInfo)
          this.lookupDataSelected.industry = this.lookupData.industry.filter(item=>item.value==res.data.industry)
          this.lookupDataSelected.cnty = this.lookupData.cnty.filter(item=>item.value==res.data.country)
          this.loadQuotationData()
        }).catch((res)=>{
          if(res.message=='ERR_NO_EXIST'){
            Swal.fire("System Error",this.$t("order.errorOrderExits"), "error").then(()=>{
              this.$router.go(-1)
            });
          }else{
            Swal.fire("System Error", this.$t("order.errorRequest"), "error").then(()=>{
              this.$router.go(-1)
            });
          }
        })
      }
      getNetDiskFile({code:["INVOICE_DATA"]}).then((res)=>{
          this.attachmentFiles = res.data;
      })
    },
    handleAssignSales(){
      this.$refs.salesModal.show()
    },
    assignSales(){
      let sales = this.curSales.userName
      setOrderSales({orderId:this.form.orderId,handleSales:sales}).then((res)=>{
        this.form.handleSales= res.data.sales
        this.$refs.salesModal.hide()
        Vue.swal(this.$t("common.assignCompleted"));
      })
    },
    handleUpdateStatus(){
        this.orderStatusShow = true;
    },
    handleRelateClient(){
        this.relateClientShow = true;
    },
    handleCloseOrderStatusModal(curOrder){
      if(curOrder!=null){
        this.form = curOrder
        this.form.orderDetail = JSON.parse(curOrder.orderDetail)
        this.form.companyInfo = JSON.parse(curOrder.companyInfo)
        this.lookupDataSelected.industry = this.lookupData.industry.filter(item=>item.value==curOrder.industry)
      }
      this.orderStatusShow = false
    },
    handleCloseRelateClientModal(clientId){
      if(clientId!=null){
        this.form.clientId = clientId;
      }
      this.relateClientShow = false
    },
    handleSave() {
      this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        } else {
          let params = {...this.form}
          params.companyInfo = JSON.stringify(this.form.companyInfo)
          params.orderDetail = JSON.stringify(this.form.orderDetail)
          if(this.lookupDataSelected.industry.value!=undefined){
            params.industry = this.lookupDataSelected.industry.value
          }
          if(this.lookupDataSelected.cnty.value!=undefined){
            params.country = this.lookupDataSelected.cnty.value
          }
          saveOrder(params).then(()=>{
            this.handleBack();
          })
        }
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleNewQuotation(){
      if(this.quotationData.length>0){
        Swal.fire({
          title: this.$t("order.infoConfirmNewQuotation"),
          text: this.$t("order.infoHisQuotatiton"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t("actions.ok"),
          cancelButtonText: this.$t("actions.cancel")
        }).then(result => {
          if (result.value) {
            this.showNewQuotationForm();
          }
        });
      }else{
        this.showNewQuotationForm();
      }
    },
    showNewQuotationForm(){
      this.quotation = {...this.quotationInitData}
      this.workDay = 1;
      this.quotationAction='form'
    },
    globalTravel(city,days){
      let cityInfo = this.lookupData.travel.filter((item)=>{return item.label==city})
      if(cityInfo.length==1){
        this.quotation.serviceItems.forEach(item => {
          if(item.itemCode=="gt"){
            let price = parseInt(cityInfo[0].value)*2+200;
            let pDay = days;
            if(cityInfo[0].labelEn=="0"){
              pDay+=1;
            }
            price += 400*pDay
            item.itemPrice = Math.round(price/7.25)
          }
        });
      }
    },
    handleDownload (item){
        const url=`/api-gateway/service/download?fileType=QT&fileName=${item.quotationFile}`
        downFile(url,item.fileName);
    },
    handleSendQuotation(item){
      this.quotationEmail.to = this.form.email
      this.quotationEmail.attachment = [{name: this.$t("quotation.title"),file:item.quotationFile}]
      this.quotationEmail.emailData = {}
      this.quotationEmail.emailData.clientName = this.form.clientName
      this.quotationEmail.emailData.clientNameEn = this.form.clientNameEn
      this.quotationEmail.emailData.contact = this.form.contact
      this.quotationAction="send"
      if(this.attachmentFiles!=null && this.attachmentFiles.length>0){
            let files = this.attachmentFiles.filter(item=>{return item.fileCode=="INVOICE_DATA"})
            if(files.length==1){
              this.quotationEmail.attachment.push({name:files[0].fileName,file:files[0].fileOrgName})
            }
        }  
    },
    addPrice(){
      this.quotation.serviceItems.push({
          itemType:1,
          itemName:"Item",
          itemPrice:0
      })
    },
    deletePrice(index){
      this.quotation.serviceItems.splice(index, 1);
    },
    handleAddQuotation(){
      const params = {...this.quotation}
      params.orderId = this.form.orderId
      params.services = JSON.stringify(this.quotation.serviceItems)
      delete params.serviceItems
      saveQuotation(params).then(()=>{
        this.loadQuotationData();
        this.quotationAction = 'data'
      })
    },
    handleCancelQuotation(){
      this.quotationAction = 'data'
    },
    loadQuotationData(){
      const params = {filter:{orderId:this.form.orderId}};
      queryQuotation(params).then((res)=>{
          this.quotationData = res.data.map((item)=>{
            return {...item,serviceItems:JSON.parse(item.services)}
          });
      });
    },
    handleSendQuotationEmailResult(res){
      if(res.code==0){
        this.quotationAction = "data"
      }else if(res.code==1){
        Vue.swal( this.$t("order.infoQuotationSent"));
        this.quotationAction = "data"
      }else{
        Vue.swal( this.$t("order.errorSent"));
      }
    },
    handleFollowup(){
      let orderPath="follow-up"
      this.$router.push({
          name: orderPath,
          query: {
              id: this.orderNo,
              type:'order',
          }
      })
    },
    showDownloadQuotation(quotation){
      if(quotation.quotationFile!=null && quotation.quotationFile!=""){
        return true
      }else{
        return false
      }
    },
    handleReCreateQuotation(){
      createQuotationFile(this.orderNo).then(()=>{
        this.loadQuotationData()
      })
    },
    handleCreateFormFile(){
      createApplicationForm({"orderNo":this.form.orderNo}).then((res)=>{
        this.form.formFile = res.data
        this.handleDownloadFormFile()
      })
    },
    handleDownloadFormFile(){
      const url=`/api-gateway/service/download?fileType=QT&fileName=${this.form.formFile}`
        downFile(url,"");
    }    
  },
};
</script>

<template>
  <Layout>
    <div class="row  mt-4">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4">
                <h5 class="card-title mb-4">{{$t("zdhc.formName")}}</h5>
              </div>
              <div class="col-sm-8">
                <div class="float-end d-none d-md-block">
                  <a href="javascript:" @click="handleRelateClient" class="btn-sm btn-primary" v-if="showRelateClientButton">{{$t("actions.bindingClient")}}</a>
                  <a href="javascript:" @click="handleFollowup" class="btn-sm btn-info ms-1" v-if="showFollowupButton">{{$t("actions.followup")}}</a>
                  <a href="javascript:" @click="handleUpdateStatus" class="btn-sm btn-success ms-1" v-if="showFollowupButton">{{$t("actions.status")}}</a>
                  <a href="javascript:" @click="handleCreateFormFile" class="btn-sm btn-info ms-1" v-if="showFollowupButton">{{$t("actions.createAppForm")}}</a>
                  <a href="javascript:" @click="handleDownloadFormFile" class="btn-sm btn-success ms-1" v-if="showDownloadFormFile">{{$t("actions.downAppForm")}}</a>
                  <a href="javascript:" @click="handleAssignSales" class="btn-sm btn-primary ms-1" v-if="showAssignButton">{{$t("actions.assignSales")}}</a>
                  <a href="javascript:" @click="handleBack" class="btn-sm btn-light ms-1">{{$t("actions.back")}}</a>
                </div>
              </div>
            </div>
            <orderstatus :show="orderStatusShow" :data="form" @close="handleCloseOrderStatusModal"></orderstatus>
            <relateclient :show="relateClientShow" :data="form" @close="handleCloseRelateClientModal"></relateclient>
            <form class="needs-validation">
              <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    01
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">{{$t("common.verfRequirement")}}</h5>
                        </div>
                    </div>
                    <div class="card-body">
                      <div class="col-lg-12 mb-3">
                          <label class="form-label mb-3 d-flex">{{$t("zdhc.experience")}}:</label>
                          <div class="form-check form-check-inline" v-for="(item,index) in lookupData.experience" :key="index">
                            <input
                              type="radio"
                              class="form-check-input"
                              :value="item.value"
                              v-model="form.orderDetail.experience"
                            />
                            <label class="form-check-label"
                              >{{item.label}}</label
                            >
                          </div>                          
                      </div>
                      <div class="col-lg-12 mb-3">
                          <label class="form-label mb-3 d-flex">{{$t("zdhc.performance")}}:</label>
                          <div class="form-check form-check-inline" v-for="(item,index) in lookupData.yesno" :key="index">
                            <input
                              type="radio"
                              class="form-check-input"
                              :value="item.value"
                              v-model="form.orderDetail.performance"
                            />
                            <label class="form-check-label"
                              >{{item.label}}</label
                            >
                          </div>                          
                      </div>
                      <div class="col-lg-12 mb-3">
                          <label class="form-label mb-3 d-flex">{{$t("zdhc.model")}}:</label>
                          <div class="form-check form-check-inline" v-for="(item,index) in lookupData.model" :key="index">
                            <input
                              type="radio"
                              class="form-check-input"
                              :value="item.value"
                              v-model="form.orderDetail.model"
                            />
                            <label class="form-check-label"
                              >{{item.label}}</label
                            >
                          </div>                          
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <label class="form-label" for="zdhcID">{{$t("zero.zdhcID")}}:</label>
                          <input
                            type="text"
                            class="form-control"
                            id="zdhcID"
                            v-model="form.orderDetail.zdhcID"
                            :class="{
                                            'is-invalid': submitted && $v.form.orderDetail.zdhcID.$error,
                                        }"/>
                                        <div
                                            v-if="submitted && $v.form.orderDetail.zdhcID.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.orderDetail.zdhcID.required">
                                                  {{$t("common.requiredError")}}.
                                                </span>
                                        </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <label class="form-label" for="promoteBrand">{{$t("fem.promoteBrand")}} :</label>
                          <input
                            type="email"
                            class="form-control"
                            id="promoteBrand"
                            v-model="form.promoteBrand"
                            :class="{
                                            'is-invalid': submitted && $v.form.promoteBrand.$error,
                                        }"/>
                                        <div
                                            v-if="submitted && $v.form.promoteBrand.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.promoteBrand.required">
                                                  {{$t("common.requiredError")}}.
                                                </span>
                                        </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">{{$t("zero.serviceDate")}} :</label>
                        <div class="row">
                          <div class="col-md-6">
                            <date-picker
                              v-model="form.orderDetail.auditDateFrom"
                              format="YYYY-MM-DD"
                              value-type="format"
                              :disabled-date="disabledDates"
                              :first-day-of-week="1"
                              lang="en"
                              placeholder="from"
                              :class="{
                                  'is-invalid': submitted && $v.form.orderDetail.auditDateFrom.$error,
                              }"
                            ></date-picker>
                            <div
                                v-if="submitted && $v.form.orderDetail.auditDateFrom.$error"
                                class="invalid-feedback"
                                >
                                    <span v-if="!$v.form.orderDetail.auditDateFrom.required">
                                      {{$t("fem.auditDateError")}}.
                                    </span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <date-picker
                              v-model="form.orderDetail.auditDateTo"
                              format="YYYY-MM-DD"
                              value-type="format"
                              :disabled-date="disabledDates"
                              :first-day-of-week="1"
                              lang="en"
                              placeholder="to"
                              :class="{
                                  'is-invalid': submitted && $v.form.orderDetail.auditDateTo.$error,
                              }"
                            ></date-picker>
                            <div
                                v-if="submitted && $v.form.orderDetail.auditDateTo.$error"
                                class="invalid-feedback"
                                >
                                    <span v-if="!$v.form.orderDetail.auditDateTo.required">
                                      {{$t("fem.auditDateError")}}.
                                    </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    02
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">{{$t("common.baseInfo")}}</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-lg-6 mb-3">
                                        <label class="form-label" for="gen-info-name-input">{{$t("common.clientNameLocal")}}</label>
                                        <input type="text" class="form-control" id="gen-info-name-input" v-model="form.clientName"
                                        :class="{
                                            'is-invalid': submitted && $v.form.clientName.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.clientName.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.clientName.required">
                                                  {{$t("common.clientNameLocalError")}}.
                                                </span>
                                        </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-name-en-input">{{$t("common.clientNameEn")}}</label>
                                        <input type="text" class="form-control" id="gen-info-name-en-input" v-model="form.clientNameEn">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6 mb-3">
                                      <label class="form-label" for="gen-info-taxcode-input">{{$t("common.taxCode")}}</label>
                                      <input type="text" class="form-control" id="gen-info-taxcode-input" v-model="form.taxCode"
                                      :class="{
                                          'is-invalid': submitted && $v.form.taxCode.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.taxCode.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.taxCode.check">
                                                {{$t("common.taxCodeError")}}.
                                              </span>
                                      </div>
                              </div>
                          </div>                                  
                            <div class="row">
                              <div class="col-lg-6 mb-3">
                                <label class="form-label" for="gen-info-country-input">{{$t("common.country")}}</label>
                                  <multiselect 
                                  id="gen-info-country-input"
                                  v-model="lookupDataSelected.cnty" 
                                  :options="lookupData.cnty"
                                  label="label"
                                  track-by="value"
                                  :class="{
                                          'is-invalid': submitted && $v.form.country.$error,
                                      }"
                                  ></multiselect>
                                  <div
                                      v-if="submitted && $v.form.country.$error"
                                      class="invalid-feedback"
                                      >
                                          <span v-if="!$v.form.country.required">
                                            {{$t("common.countryError")}}.
                                          </span>
                                  </div>
                              </div>
                              <div class="col-lg-6 mb-3">
                                    <label class="form-label" for="gen-info-province-input">{{$t("common.province")}}</label>
                                    <input type="text" class="form-control" id="gen-info-province-input" v-model="form.province">
                            </div>                                     
                          </div>                            
                            <div class="row">
                                <div class="col-lg-6 mb-3">
                                        <label class="form-label" for="gen-info-address-input">{{$t("common.addressLocal")}}</label>
                                        <input type="text" class="form-control" id="gen-info-address-input" v-model="form.address"
                                        :class="{
                                            'is-invalid': submitted && $v.form.address.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.address.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.address.required">
                                                  {{$t("common.addressLocalError")}}.
                                                </span>
                                        </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="gen-info-address-en-input">{{$t("common.addressEn")}}</label>
                                        <input type="text" class="form-control" id="gen-info-address-en-input" v-model="form.addressEn">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6 mb-3">
                                      <label class="form-label" for="gen-info-station-input">{{$t("common.station")}}</label>
                                      <input type="text" class="form-control" id="gen-info-station-input" v-model="form.station">
                              </div> 
                              <div class="col-lg-6 mb-3">
                                      <label class="form-label" for="gen-info-station-input">{{$t("zero.fromFactoryAirport")}}</label>
                                      <input type="text" class="form-control" id="gen-info-station-input" v-model="form.companyInfo.fromFactoryAirport">
                              </div>                                               
                          </div>   
                          <div class="row">
                              <div class="col-lg-6 mb-3">
                                <label class="form-label" for="employee">{{$t("zero.openingHour")}}</label>
                                <input type="text" class="form-control" id="employee"  v-model="form.companyInfo.openingHour"
                                :class="{
                                          'is-invalid': submitted && $v.form.companyInfo.openingHour.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.companyInfo.openingHour.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.companyInfo.openingHour.required">
                                                {{$t("common.requiredError")}}.
                                              </span>
                                      </div>
                              </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="employee">{{$t("fem.employee")}}</label>
                                        <input type="text" class="form-control" id="employee"  v-model="form.companyInfo.employee"
                                        :class="{
                                            'is-invalid': submitted && $v.form.companyInfo.employee.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.companyInfo.employee.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.companyInfo.employee.required">
                                                  {{$t("fem.employeeError")}}.
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3 mb-3">
                                <label class="form-label" for="productionArea">{{$t("zdhc.productionArea")}}</label>
                                <input type="text" class="form-control" id="productionArea"  v-model="form.companyInfo.productionArea"
                                :class="{
                                          'is-invalid': submitted && $v.form.companyInfo.productionArea.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.companyInfo.productionArea.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.companyInfo.productionArea.required">
                                                {{$t("common.requiredError")}}.
                                              </span>
                                      </div>
                              </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label" for="administration">{{$t("zdhc.administration")}}</label>
                                        <input type="text" class="form-control" id="administration"  v-model="form.companyInfo.administration"
                                        :class="{
                                            'is-invalid': submitted && $v.form.companyInfo.administration.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.companyInfo.administration.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.companyInfo.administration.required">
                                                  {{$t("common.requiredError")}}.
                                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label" for="chemicalStorageArea">{{$t("zdhc.chemicalStorageArea")}}</label>
                                        <input type="text" class="form-control" id="chemicalStorageArea"  v-model="form.companyInfo.chemicalStorageArea"
                                        :class="{
                                            'is-invalid': submitted && $v.form.companyInfo.chemicalStorageArea.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.companyInfo.chemicalStorageArea.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.companyInfo.chemicalStorageArea.required">
                                                  {{$t("common.requiredError")}}.
                                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label" for="other">{{$t("zdhc.other")}}</label>
                                        <input type="text" class="form-control" id="other"  v-model="form.companyInfo.other"
                                        :class="{
                                            'is-invalid': submitted && $v.form.companyInfo.other.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.companyInfo.other.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.companyInfo.other.required">
                                                  {{$t("common.requiredError")}}.
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 mb-3">
                                    <label class="form-label" for="gen-info-industry-input">{{$t("common.industry")}}</label>
                                    <multiselect 
                                    id="gen-info-industry-input"
                                    v-model="lookupDataSelected.industry" 
                                    :options="lookupData.industry"
                                    label="label"
                                    track-by="value"
                                    :class="{
                                          'is-invalid': submitted && $v.form.industry.$error,
                                      }"
                                  ></multiselect>
                                  <div
                                      v-if="submitted && $v.form.industry.$error"
                                      class="invalid-feedback"
                                      >
                                          <span v-if="!$v.form.industry.required">
                                            {{$t("common.requiredError")}}.
                                          </span>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="wwt">{{$t("fem.wwt")}}</label>
                                        <input type="text" class="form-control" id="wwt"  v-model="form.companyInfo.wwt"
                                        :class="{
                                            'is-invalid': submitted && $v.form.companyInfo.wwt.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.companyInfo.wwt.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.companyInfo.wwt.required">
                                                  {{$t("common.pleaseDetail")}}.
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 mb-3">
                                  <label class="form-label" for="materials">{{$t("zero.materials")}}</label>
                                  <input type="text" class="form-control" id="materials"   v-model="form.companyInfo.materials"
                                  :class="{
                                          'is-invalid': submitted && $v.form.companyInfo.materials.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.companyInfo.materials.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.companyInfo.materials.required">
                                                {{$t("common.requiredError")}}.
                                              </span>
                                      </div>
                                </div>
                                <div class="col-lg-4 mb-3">
                                  <label class="form-label" for="productType">{{$t("fem.productType")}}</label>
                                  <input type="text" class="form-control" id="productType"   v-model="form.companyInfo.productType"
                                  :class="{
                                          'is-invalid': submitted && $v.form.companyInfo.productType.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.companyInfo.productType.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.companyInfo.productType.required">
                                                {{$t("common.requiredError")}}.
                                              </span>
                                      </div>
                                </div>
                                <div class="col-lg-4 mb-3">
                                  <label class="form-label" for="technology">{{$t("fem.technology")}}</label>
                                  <input type="text" class="form-control" id="technology"  v-model="form.companyInfo.technology"
                                  :class="{
                                          'is-invalid': submitted && $v.form.companyInfo.technology.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.companyInfo.technology.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.companyInfo.technology.required">
                                                {{$t("common.requiredError")}}.
                                              </span>
                                      </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 mb-3">
                                        <label class="form-label" for="cmc"> {{$t("zero.cmc")}}</label>
                                        <input type="text" class="form-control" id="cmc" v-model="form.companyInfo.cmc"
                                        :class="{
                                          'is-invalid': submitted && $v.form.companyInfo.cmc.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.companyInfo.cmc.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.companyInfo.cmc.required">
                                                {{$t("common.requiredError")}}.
                                              </span>
                                      </div>
                                </div>
                                <div class="col-lg-4 mb-3">
                                        <label class="form-label" for="numberofChemical"> {{$t("zero.numberofChemical")}}</label>
                                        <input type="text" class="form-control" id="numberofChemical" v-model="form.companyInfo.numberofChemical"
                                        :class="{
                                          'is-invalid': submitted && $v.form.companyInfo.numberofChemical.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.companyInfo.numberofChemical.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.companyInfo.numberofChemical.required">
                                                {{$t("common.requiredError")}}.
                                              </span>
                                      </div>
                                </div>
                                <div class="col-lg-4 mb-3">
                                        <label class="form-label" for="chemicalUsed"> {{$t("zero.chemicalUsed")}}</label>
                                        <input type="text" class="form-control" id="chemicalUsed" v-model="form.companyInfo.chemicalUsed"
                                        :class="{
                                          'is-invalid': submitted && $v.form.companyInfo.chemicalUsed.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.companyInfo.chemicalUsed.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.companyInfo.chemicalUsed.required">
                                                {{$t("common.requiredError")}}.
                                              </span>
                                      </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 mb-3">
                                        <label class="form-label" style="padding-right: 10px;"> {{$t("zero.mrsl")}}</label>
                                        <div class="form-check form-check-inline" v-for="(item,index) in lookupData.yesno" :key="index">
                                          <input
                                            type="radio"
                                            class="form-check-input"
                                            :value="item.value"
                                            v-model="form.companyInfo.mrsl"
                                          />
                                          <label class="form-check-label">{{item.label}}</label>
                                      </div>        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    03
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">{{$t("common.contactInfo")}}</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-lg-6 mb-3">
                                        <label class="form-label" for="gen-info-contact-input">{{$t("common.contact")}}</label>
                                        <input type="text" class="form-control" id="gen-info-contact-input" v-model="form.contact"
                                        :class="{
                                            'is-invalid': submitted && $v.form.contact.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.contact.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.contact.required">
                                                  {{$t("common.contactError")}}.
                                                </span>
                                        </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                  <label class="form-label" for="gen-info-title-input">{{$t("common.title")}}</label>
                                        <input type="text" class="form-control" id="gen-info-title-input" v-model="form.title"
                                        :class="{
                                            'is-invalid': submitted && $v.form.title.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.title.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.title.required">
                                                  {{$t("common.titleError")}}.
                                                </span>
                                        </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 mb-3">
                                        <label class="form-label" for="gen-info-mobile-input">{{$t("common.mobile")}}</label>
                                        <input type="text" class="form-control" id="gen-info-mobile-input" v-model="form.mobile"
                                        :class="{
                                            'is-invalid': submitted && $v.form.mobile.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.mobile.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.mobile.required">
                                                  {{$t("common.mobileError")}}.
                                                </span>
                                        </div>
                                </div>
                                <div class="col-lg-6 mb-3" >
                                        <label class="form-label" for="gen-info-email-input">{{$t("common.email")}}</label>
                                        <input type="text" class="form-control" id="gen-info-email-input"  v-model="form.email"
                                        :class="{
                                            'is-invalid': submitted && $v.form.email.$error,
                                        }">
                                        <div
                                            v-if="submitted && $v.form.email.$error"
                                            class="invalid-feedback"
                                            >
                                                <span v-if="!$v.form.email.required">
                                                  {{$t("common.emailError")}}.
                                                </span>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </form>
              <!-- end form -->
                <div class="card border shadow-none mb-5" v-if="action=='detail'">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    04
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">{{$t("quotation.title")}}</h5>
                        </div>
                        <button type="button" class="btn btn-success btn-sm" @click="handleNewQuotation" v-if="enableNewQuotation">{{$t("actions.add")}}</button>
                    </div>
                    <div class="card-body">
                      <div class="row" v-show="quotationAction=='data'">
                        <div class="col-xl-4 col-sm-6" v-for="quotationItem in quotationData" :key="quotationItem.quotationId">
                            <div class="card">
                                <div class="card-body p-4">
                                    <div class="d-flex align-items-start">
                                        <div class="flex-grow-1 overflow-hidden">
                                          {{quotationItem.createDate}}
                                          <h5 class="font-size-15 mb-1 text-truncate">{{$t("quotation.total")}}: <span v-if="quotationItem.currencyType=='USD'">USD {{quotationItem.total}},</span>RMB {{quotationItem.totalRmb}} ({{$t("quotation.personDays")}}:{{quotationItem.workDay}})</h5>                                                 
                                        </div>
                                    </div>
                                    <p class="mt-4 text-muted">
                                      <ul>
                                        <li v-for="(cost,index) in quotationItem.serviceItems" :key="index">{{cost.itemName}}: {{cost.itemType==1?cost.itemPrice:-cost.itemPrice}}</li>
                                        <li>{{$t("quotation.vat")}}：{{quotationItem.vat}}%</li>
                                      </ul>
                                    </p>
                                    <div class="pt-2" v-if="quotationItem.quotationStatus==1">
                                        <button type="button" class="btn btn-primary btn-sm" @click="handleDownload(quotationItem)" v-if="showDownloadQuotation(quotationItem)"><i class="ion ion-md-cloud-download me-1 align-middle" ></i>{{$t("actions.download")}}</button>
                                        <button type="button" class="btn btn-primary btn-sm" @click="handleReCreateQuotation()" v-if="!showDownloadQuotation(quotationItem)"><i class="ion ion-md-cloud-download me-1 align-middle" ></i>{{$t("actions.createQuotation")}}</button>
                                        <button type="button" class="btn btn-primary btn-sm  ms-1" @click="handleSendQuotation(quotationItem)" v-if="showDownloadQuotation(quotationItem)"><i class="ion ion-md-send me-1 align-middle" ></i> {{$t("actions.send")}}</button>
                                    </div>
                                </div>
                                <!-- end card body -->
                            </div><!-- end card -->
                        </div><!-- end col -->
                      </div><!-- end row -->
                      <div v-if="quotationAction=='form'">
                        <div class="row">
                              <div class="col-lg-6 mb-3">
                                <div class="form-check form-check-inline" style="padding-left:0PX;">
                                  <label class="form-check-label" >{{$t("quotation.language")}}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    id="language1"
                                    name="language"
                                    class="form-check-input"
                                    value="CN"
                                    v-model="quotation.language"
                                  />
                                  <label class="form-check-label" for="language1">{{$t("quotation.languageCn")}}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    id="language2"
                                    name="language"
                                    class="form-check-input"
                                    value="EN"
                                    v-model="quotation.language"
                                  />
                                  <label class="form-check-label" for="language2">{{$t("quotation.languageEn")}}</label>
                                </div>
                              </div>
                              <div class="col-lg-6 mb-3">
                                <div class="form-check form-check-inline" style="padding-left:0PX;">
                                  <label class="form-check-label" >{{$t("quotation.currencyType")}}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    id="currencyType1"
                                    name="currencyType"
                                    class="form-check-input"
                                    value="RMB"
                                    v-model="quotation.currencyType"
                                  />
                                  <label class="form-check-label" for="currencyType1">RMB</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    id="currencyType2"
                                    name="currencyType"
                                    class="form-check-input"
                                    value="USD"
                                    v-model="quotation.currencyType"
                                  />
                                  <label class="form-check-label" for="currencyType2">USD</label>
                                </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-6 mb-3">
                                      <label class="form-label" for="gen-info-workDay-input">{{$t("quotation.personDays")}}</label>
                                      <input type="text" class="form-control" id="gen-info-workDay-input" v-model="workDay"
                                      :class="{
                                          'is-invalid': submitted && $v.form.contact.$error,
                                      }">
                              </div>
                              <div class="col-lg-6 mb-3">
                                <label class="form-label" for="gen-info-vat-input">{{$t("quotation.vat")}}</label>
                                      <input type="text" class="form-control" id="gen-info-vat-input" v-model="quotation.vat"
                                      :class="{
                                          'is-invalid': submitted && $v.form.title.$error,
                                      }">
                              </div>
                          </div>
                          <div class="inner-repeater mb-4">
                            <div
                              data-repeater-list="inner-group"
                              class="inner form-group"
                            >
                              <label class="form-label">{{$t("quotation.detail")}}
                                <input
                                data-repeater-create
                                type="button"
                                class="btn btn-success btn-sm inner "
                                :value='$t("actions.add")'
                                @click="addPrice"
                              /></label>
                              <div
                                v-for="(data, index) in quotation.serviceItems"
                                :key="data.id"
                                class="inner mb-3 row"
                              >
                                <div class="col-md-2 col-sm-6">
                                  <select class="form-select" v-model="data.itemType">
                                    <option value=1>{{$t("quotation.add")}}</option>
                                    <option value=0>{{$t("quotation.subtract")}}</option>
                                  </select>
                                </div>
                                <div class="col-md-5 col-sm-6">
                                  <input
                                    v-model="data.itemName"
                                    type="text"
                                    class="inner form-control"
                                    placeholder="Item"
                                  />
                                </div>
                                <div class="col-md-3 col-sm-6">
                                  <input
                                    v-model="data.itemPrice"
                                    type="text"
                                    class="inner form-control"
                                    placeholder="Price"
                                  />
                                </div>
                                <div class="col-md-2 col-sm-6">
                                  <div class="d-grid">
                                    <input
                                      type="button"
                                      class="btn btn-primary btn-block inner btn-sm"
                                      :value='$t("actions.delete")'
                                      @click="deletePrice(index)"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="inner-repeater mb-4">
                            <div
                              data-repeater-list="inner-group"
                              class="inner form-group"
                            >
                              <label class="form-label">{{$t("quotation.total")}}</label>
                              <div class="inner mb-3 row">
                                <div class="col-md-3 col-sm-6" v-if="quotation.currencyType=='USD'">
                                  <input
                                    v-model="totalUsd"
                                    type="text"
                                    class="inner form-control"
                                    disabled
                                  />
                                </div>
                                <div class="col-md-3 col-sm-6">
                                  <input
                                    v-model="totalRmb"
                                    type="text"
                                    class="inner form-control"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button type="button" class="btn btn-info btn-sm ms-1" @click="handleAddQuotation">{{$t("actions.ok")}}</button>
                            <button type="button" class="btn btn-light btn-sm ms-1" @click="handleCancelQuotation">{{$t("actions.cancel")}}</button>
                          </div>
                      </div>
                      <div v-if="quotationAction=='send'">
                        <email :templateId="templateId" :data="quotationEmail" @send="handleSendQuotationEmailResult"></email>
                      </div>
                    </div>
              </div>
              <div class="text-end">
                  <button type="button" class="btn btn-primary w-sm" @click="handleSave" v-if="this.action!='detail'">{{$t("actions.submit")}}</button>
                  <button type="button" class="btn btn-success w-sm ms-1" @click="handleBack">{{$t("actions.back")}}</button>
              </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <b-modal
        id="salesModal"
        ref="salesModal"
        centered
        :title='$t("actions.assignSales")'
        title-class="font-18"
        hide-footer
        >
          <div>
            <multiselect v-model="curSales" :options="salesList" :multiple="false"
                    label="staffName"
                    track-by="userName"
            ></multiselect>
          </div>
          <div>
            <button type="button" class="btn btn-primary btn-sm" @click="assignSales">{{$t("common.ok")}}</button>
          </div>
      </b-modal>
  </Layout>
</template>
<style lang="scss">
.is-invalid .mx-input-wrapper .mx-input{
    border: 1px solid #ec4561 !important;
  }
  .is-invalid .mx-input-wrapper .mx-icon-calendar{
    color: #ec4561 !important;
  }
  .is-invalid .multiselect__tags{
    border:1px solid #ec4561 !important;
  }
</style>
